<template>
    <div class="mysubscribe">
        <!-- PC端 -->
        <template v-if="isPC">
            <p class="title">RSS订阅生成<i class="iconfont icon-close" @click="$router.push({path: '/'})"></i></p>
            <div class="source-box source-box-selected line-bottom">
                <p>已订阅平台（支持拖拽排序）</p>

                <transition-group name="list-transition" tag="div">
                    <div class="source-item source-item-selected list-transition-item" v-for="(source, i) in order" :key="source.id" :data-index="i" draggable="true" @dragstart="dragStart" @dragenter="dragEnter" @dragend="dragEnd">
                        <span>{{source.name}}</span>
                        <i class="iconfont icon-close" @click="deleteOrder(i, source.id)"></i>
                    </div>
                </transition-group>

                <div class="btn-box" v-if="source[0]">
                    <p>
                        <button class="btn btn-green-full" @click="createRssLink">生成RSS订阅</button>
                        <button class="btn btn-red-full" @click="splitRssLink">解析RSS订阅</button>
                    </p>
                    <p><input class="rss-text" type="text" onfocus="this.select();" v-model="rssLink"></p>
                </div>
            </div>
            <div class="source-box" v-for="(sort, k) in source" :key="k">
                <p>{{sort.name}}</p>
                <div class="source-item" v-for="(item, i) in source[k].data" :key="'s' + i" @click="add(k, i)">{{item.name}}</div>
            </div>
        </template>
        <!-- 移动端 -->
        <template v-else>
            <div class="source-box" v-for="(sort, k) in source" :key="k">
                <p>{{sort.name}}</p>
                <div :class="{'source-item': true, 'source-item-selected': item.is_selected == 1}" v-for="(item, i) in source[k].data" :key="i" @click="select(k, i)">{{item.name}}</div>
            </div>

            <div class="btn-box" v-if="source[0]">
                <p>
                    <button class="btn btn-green-full" @click="createRssLink">生成RSS订阅</button>
                    <button class="btn btn-red-full" @click="splitRssLink">解析RSS订阅</button>
                </p>
                <p><input class="rss-text" type="text" onfocus="this.select();" v-model="rssLink"></p>
            </div>
        </template>
    </div>
</template>

<script>
Array.prototype.remove = function (val) {
    var index = this.indexOf(val)
    if (index > -1) this.splice(index, 1)
}
let start = null;
let sortObj = {};
export default {
    name: 'mysubscribe',
    data() {
        return {
            source: [],
            order: [],
            isPC: true,
            rssLink: ''
        }
    },
    methods: {
        // 开始拖动
        dragStart(e) {
            start = e.target.dataset.index
        },
        // 拖动到某一个地方
        dragEnter(e) {
            if (e.target.className == 'source-item source-item-selected list-transition-item') {
                let end = e.target.dataset.index
                if (end && start && start !== end) {
                    let preArr = this.order
                    let old = preArr[start]
                    preArr.splice(start, 1)
                    preArr.splice(end, 0, old)
                    this.order = preArr
                    start = end
                }
            }
        },
        // 结束拖动
        dragEnd() {
            start = null
        },
        // 移动端订阅选择
        select(sortIndex, itemIndex) {
            if (this.source[sortIndex].data[itemIndex].is_selected == 1) {
                this.source[sortIndex].data[itemIndex].is_selected = 0
                for (let i = 0; i < this.order.length; i++) {
                    if (this.source[sortIndex].data[itemIndex].id == this.order[i].id) {
                        this.order.splice(i, 1)
                        break
                    }
                }
            } else {
                if (this.order.length >= 40) {
                    this.$toast('订阅平台数量不能超过40个', 'warn')
                } else {
                    this.source[sortIndex].data[itemIndex].is_selected = 1
                    let canPush = true
                    for (let i = 0; i < this.order.length; i++) {
                        if (this.source[sortIndex].data[itemIndex].id == this.order[i].id) {
                            canPush = false
                            break
                        }
                    }
                    if (canPush) this.order.push(this.source[sortIndex].data[itemIndex])
                }
            }
        },
        // PC端订阅选择
        add(sortIndex, itemIndex) {
            if (this.order.length >= 40) {
                this.$toast('订阅平台数量不能超过40个', 'warn')
            } else {
                if (this.source[sortIndex].data[itemIndex].is_selected == 1) {
                    this.$toast('已订阅该平台', 'warn')
                } else {
                    this.order.push(sortObj[this.source[sortIndex].data[itemIndex].id])
                    this.source[sortIndex].data[itemIndex].is_selected = 1
                }
            }
        },
        // PC端删除已订阅
        deleteOrder(index, id) {
            this.order.splice(index, 1)
            // 从所有平台找出被删除的订阅
            for (let i = 0; i < this.source.length; i++) {
                for (let k = 0; k < this.source[i].data.length; k++) {
                    if (id == this.source[i].data[k].id) {
                        this.source[i].data[k].is_selected = 0
                        return undefined
                    }
                }
            }
        },
        // 获取订阅列表
        getList() {
            let that = this
            that.$({
                url: '/hot/source',
                success(res) {
                    let source = res.data.source
                    // 数据赋值渲染
                    that.source = JSON.parse(JSON.stringify(source))

                    // 扁平化所有平台用来匹配
                    for (let i = 0; i < source.length; i++) {
                        for (let k = 0; k < source[i].data.length; k++) {
                            sortObj[source[i].data[k].id] = source[i].data[k]
                        }
                    }

                    // 平台排序
                    let order = res.data.order.split(',')
                    let orderArr = [];
                    for (let i = 0; i < order.length; i++) {
                        sortObj[order[i]] && orderArr.push(sortObj[order[i]])
                    }
                    that.order = orderArr
                },
                error(res) {
                    if (res.status && res.status == 100401) {
                        that.$router.replace({
                            path: '/login'
                        })
                        that.$toast('需要登录')
                    } else {
                        that.$toast('获取分类列表失败', 'error')
                    }
                }
            })
        },
        // 生成RSS订阅链接
        createRssLink() {
            if (this.isPC) {
                if (this.order.length <= 0) {
                    this.$toast('请选择订阅', 'warn')
                    return false
                }
                this.rssLink = `https://momoyu.cc/api/hot/rss?code=${encode(this.order.map(i => i.id).join(','))}`
            }
            else {
                let arr = []
                for (let i = 0; i < this.source.length; i++) {
                    let item = this.source[i]
                    for (let k = 0; k < item.data.length; k++) {
                        let item2 = item.data[k]
                        this.source[i].data[k].is_selected === 1 && arr.push(item2.id)
                    }
                }
                if (arr.length <= 0) {
                    this.$toast('请选择订阅', 'warn')
                    return false
                }
                this.rssLink = `https://momoyu.cc/api/hot/rss?code=${encode(arr.join(','))}`
            }
        },
        // 解析RSS订阅链接
        splitRssLink() {
            if (this.rssLink.trim() === '') {
                this.$toast('请先粘贴原有RSS订阅链接到输入框中', 'warn')
                return false
            }
            try {
                let order = decode(this.rssLink.split('code=')[1]).split(',')
                if (this.isPC) {
                    let orderArr = [];
                    for (let i = 0; i < order.length; i++) {
                        sortObj[order[i]] && orderArr.push(sortObj[order[i]])
                    }
                    this.order = orderArr
                }
                else {
                    this.findItemById(order)
                }
            }
            catch {
                this.$toast('解析失败，请确认RSS订阅链接是否正确', 'warn')
            }
        },
        findItemById(idArr) {
            for (let i = 0; i < this.source.length; i++) {
                let item = this.source[i]
                for (let k = 0; k < item.data.length; k++) {
                    let item2 = item.data[k]
                    this.source[i].data[k].is_selected = idArr.indexOf(item2.id + '') !== -1 ? 1 : 0
                }
            }
        },
    },
    created() {
        this.getList()
    },
    mounted() {
        let that = this
        // 检测宽度变化
        that.isPC = window.innerWidth <= 768 ? false : true
        window.onresize = () => {
            that.isPC = window.innerWidth <= 768 ? false : true
        }
    },
    beforeDestroy() {
        window.onresize = null
    }
}

function encode(text) {
    var encode = encodeURI(text)
    var code = btoa(encode)
    return code
}
function decode(text) {
    var code = atob(text)
    var str = decodeURI(code)
    return str
}
</script>

<style scoped lang="less">
.mysubscribe {
    min-height: calc(100% - 180px);
    padding: 20px;
}

.mobile {
    display: none;
}

.title {
    padding-bottom: 20px;
    font-size: 1.2rem;

    > .icon-close {
        float: right;
        padding: 2px;
        font-size: 24px;
        cursor: pointer;
    }
}

// 移动端兼容
@media screen and (max-width: 768px) {
    .title > .icon-close {
        display: none;
    }
}

.source-box {
    padding-bottom: 10px;

    > p {
        padding: 10px 0;
        margin-bottom: 10px;
        font-size: 0.9rem;
        color: #666;
    }

    .source-item {
        display: inline-block;
        color: #999;
        background-color: #fff;
        padding: 10px;
        margin: 0 10px 10px 0;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
        transition: color 0.3s, background-color 0.3s;
    }

    .source-item-selected {
        color: #fff;
        background-color: #4bab5e;
    }

    // 动画
    .list-transition-item {
        transition: transform 0.4s;
    }

    .list-transition-enter,
    .list-transition-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    .list-transition-leave-active {
        position: absolute;
    }
}

// PC端已订阅
.source-box-selected {
    padding-bottom: 30px;
    margin-bottom: 20px;

    .source-item {
        cursor: move;

        > span {
            vertical-align: text-top;
            margin-right: 5px;
        }

        > i {
            cursor: pointer;
            padding: 5px;
        }
    }
}

.btn-box {
    padding-top: 40px;

    .btn {
        padding: 8px 40px;
        margin-bottom: 20px;
    }

    .rss-text {
        width: 80%;
        max-width: 800px;
        padding: 12px;
        font-size: 14px;
        background-color: #fff;
        border-radius: 6px;
        margin-bottom: 20px;
        border: none;
        outline: none;
    }
}

.theme-1 {
    .source-item {
        background-color: #1f2025;
    }

    .source-item-selected {
        color: #fff;
        background-color: #4bab5e;
    }
}

.theme-2 {
    .btn-box .rss-text {
        border-radius: 0px;
        border-width: 2px;
        border-style: solid;
        background-color: #8c8c8c;
        border-color: #111115 #d0d2d1 #d0d2d1 #111115;
    }
}

.theme-1,
.theme-3 {
    .btn-box .rss-text {
        background-color: #252c30;
    }
}
</style>